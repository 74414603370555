import React, { ReactNode } from 'react';
import { Box } from '@angellist/adapt';

export const StopPropagationEvent = ({ children }: { children: ReactNode }) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  return <Box onClick={handleClick}>{children}</Box>;
};

export const withStopPropagationEvent = (WrappedComponent: any) => (
  props: any,
) => (
  <StopPropagationEvent>
    <WrappedComponent {...props} />
  </StopPropagationEvent>
);
